import { HeadFC, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  DesignLayout,
  ElementCard,
  Navbar,
  Seo,
  VersionCard,
} from '../../../components';
import { SubsectionText2 } from '../../../components/design/cards/style';

const MobileMenuPage: FC<PageProps> = () => {
  return (
    <DesignLayout>
      <VersionCard title="Menu - Mobile" />

      <ElementCard title="Components">
        <SubsectionText2>
          To see the mobile menu, activate the mobile view in the browser
        </SubsectionText2>

        <MenuContainerStyle>
          <Navbar />
        </MenuContainerStyle>
      </ElementCard>
    </DesignLayout>
  );
};
export default MobileMenuPage;

export const Head: HeadFC = () => <Seo title="Theme" />;

const MenuContainerStyle = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
`;
